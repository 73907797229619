import { createApp } from 'vue';

import './styles.css';
import '@fortawesome/fontawesome-free/js/all.js';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';

import router from './router.js';
import api from './plugins/api.js';

import App from './App.vue';
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import VCPresentation from './components/VCPresentation.vue';

const app = createApp(App);

app.use(router);
app.use(api);

app.component('the-header', TheHeader);
app.component('the-footer', TheFooter);
app.component('vc-presentation', VCPresentation);
app.component('custom-loader', BounceLoader);

app.mount('#app');
