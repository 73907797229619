<template>
  <div
    class="bg-secondary shadow-lg overflow-hidden w-full md:rounded-3xl md:border-secondary md:border-2 mx-auto"
  >
    <div class="bg-tertiary p-8">
      <div v-if="verifiableCredential.type == 'VerifiableCredential'">
        <div class="flex items-center">
          <img
            :src="
              verifiableCredential.image &&
              verifiableCredential.image.indexOf('https://') != -1
                ? verifiableCredential.image
                : require('/assets/images/vcImage.svg')
            "
            alt=""
            class="drop-shadow-custom w-24 h-24 mr-5"
          />
          <div>
            <p class="text-l text-secondary">
              Verifiable credential for :
              {{ verifiableCredential.key }}
            </p>
            <p class="text-xl font-bold text-primary mb-2">
              {{ verifiableCredential.value }}
            </p>
            <p class="text-xs text-secondary break-all">
              Owner :
              {{ verifiableCredential.owner }}
            </p>
            <p class="text-xs text-secondary">
              Created on :
              {{ new Date(verifiableCredential.created).toLocaleDateString() }}
            </p>
            <p
              v-if="verifiableCredential.selfsigned"
              class="text-xs text-secondary break-all"
            >
              Self-signed
            </p>
            <div class="flex mt-3">
              <p
                @click="showDynamicQrCode = true"
                class="bg-secondary text-xs font-bold px-2 py-1 rounded-md cursor-pointer"
              >
                <i class="fas fa-qrcode mr-2"></i>Scan credential
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="verifiableCredential.type == 'OpenBadgeCredential'">
        <div class="flex items-center mb-5">
          <img
            :src="verifiableCredential.image"
            alt=""
            class="drop-shadow-custom w-24 h-24 mr-5"
          />
          <div>
            <p class="text-l text-secondary">Open Badge Credential</p>
            <p class="text-xl font-bold text-primary mb-2">
              {{ verifiableCredential.customName }}
            </p>
            <p class="text-xs text-secondary break-all">
              Owner :
              {{ verifiableCredential.owner }}
            </p>
            <p class="text-xs text-secondary">
              Created on :
              {{ new Date(verifiableCredential.created).toLocaleDateString() }}
            </p>
            <p class="text-xs text-secondary">
              Description :
              {{ verifiableCredential.customDescription }}
            </p>
            <div class="flex mt-3">
              <p
                @click="showDynamicQrCode = true"
                class="bg-secondary text-xs font-bold px-2 py-1 rounded-md cursor-pointer"
              >
                <i class="fas fa-qrcode mr-2"></i>Scan credential
              </p>
            </div>
          </div>
        </div>
        <p class="text-xs text-secondary">
          Template name :
          {{ verifiableCredential.name }}
        </p>
        <p class="text-xs text-secondary">
          Template description :
          {{ verifiableCredential.description }}
        </p>
        <p class="text-xs text-secondary">
          Criteria id :
          {{ verifiableCredential.criteriaId }}
        </p>
        <p class="text-xs text-secondary">
          Criteria narrative :
          {{ verifiableCredential.criteriaNarrative }}
        </p>
      </div>
      <div v-else-if="verifiableCredential.type == 'EndorsementCredential'">
        <div class="flex items-center mb-5">
          <img
            :src="verifiableCredential.image"
            alt=""
            class="drop-shadow-custom w-24 h-24 mr-5"
          />
          <div>
            <p class="text-l text-secondary">Endorsement Credential</p>
            <p class="text-xl font-bold text-primary mb-2">
              {{ verifiableCredential.name }}
            </p>
            <p class="text-xs text-secondary break-all">
              Owner :
              {{ verifiableCredential.owner }}
            </p>
            <p class="text-xs text-secondary">
              Created on :
              {{ new Date(verifiableCredential.created).toLocaleDateString() }}
            </p>
            <p class="text-xs text-secondary">
              Description :
              {{ verifiableCredential.description }}
            </p>
            <div class="flex mt-3">
              <p
                @click="showDynamicQrCode = true"
                class="bg-secondary text-xs font-bold px-2 py-1 rounded-md cursor-pointer"
              >
                <i class="fas fa-qrcode mr-2"></i>Scan credential
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr class="border-0.5 border-primary opacity-50 my-5" />
      <div class="flex items-center">
        <img
          :src="
            verifiableCredential.issuer.type == 'Issuer Profile'
              ? verifiableCredential.issuer.profileImage.replace(
                  'https://ipfs.infura.io/ipfs/',
                  'https://myntfsid.mypinata.cloud/ipfs/'
                )
              : verifiableCredential.endorsement
              ? verifiableCredential.endorsement.image
              : require('/assets/images/user.png')
          "
          alt=""
          class="drop-shadow-custom w-24 h-24 mr-5"
        />
        <div>
          <p class="text-l text-secondary">Issued by :</p>
          <p class="text-xl font-bold text-primary break-all mb-2">
            {{
              verifiableCredential.issuer.type == 'Issuer Profile'
                ? verifiableCredential.issuer.name
                : verifiableCredential.issuer.did
            }}
          </p>
          <div v-if="verifiableCredential.issuer.type == 'Issuer Profile'">
            <p class="text-xs text-secondary">
              DID :
              {{ verifiableCredential.issuer.did }}
            </p>
            <p class="text-xs text-secondary">
              Website :
              <a
                class="underline"
                target="_blank"
                :href="verifiableCredential.issuer.website"
                >{{ verifiableCredential.issuer.website }}</a
              >
            </p>
            <p class="text-xs text-secondary">
              Email :
              <a
                target="_blank"
                :href="'mailto:' + verifiableCredential.issuer.email"
                >{{ verifiableCredential.issuer.email }}</a
              >
            </p>
          </div>
          <div v-if="verifiableCredential.endorsement">
            <p class="text-xs text-secondary">
              Role :
              {{ verifiableCredential.endorsement.name }}
            </p>
            <p class="text-xs text-secondary">
              Role issued by :
              {{ verifiableCredential.endorsement.issuer.name }}
            </p>
            <p class="text-xs text-secondary">
              Created on :
              {{
                new Date(
                  verifiableCredential.endorsement.created
                ).toLocaleDateString()
              }}
            </p>
            <p class="text-xs text-secondary">
              Description :
              {{ verifiableCredential.endorsement.description }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="showDynamicQrCode">
        <div
          @click="showDynamicQrCode = false"
          class="w-full h-full fixed top-0 left-0 z-40 bg-black bg-opacity-50"
        ></div>
        <div
          class="overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 rounded-lg shadow-lg"
        >
          <img v-if="dynamicQrCodeSrc" :src="dynamicQrCodeSrc" />
          <div v-else class="flex justify-center">
            <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import { createGif } from '../plugins/qrGif';
export default {
  props: {
    vc: Object,
  },
  setup(props) {
    const verifiableCredential = ref(props.vc);
    const dynamicQrCodeSrc = ref(null);
    const showDynamicQrCode = ref(false);

    watch(showDynamicQrCode, () => {
      if (showDynamicQrCode) {
        createGif(JSON.stringify(verifiableCredential.value.raw)).then(
          (res) => {
            dynamicQrCodeSrc.value = res;
          }
        );
      }
    });

    return {
      verifiableCredential,
      showDynamicQrCode,
      dynamicQrCodeSrc,
    };
  },
};
</script>
