<template>
  <the-header></the-header>
  <router-view v-slot="slotProps">
    <component :is="slotProps.Component"></component>
  </router-view>
  <the-footer class="mt-auto"></the-footer>
</template>

<script>
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute();

    const theme = computed(() => route.query.theme);

    watch(theme, (newTheme) => {
      document.querySelector('#app').setAttribute('data-theme', newTheme);
    });

    return { theme };
  },
};
</script>
