<template>
  <div class="p-4">
    <img
      v-if="logo"
      :src="require('/assets/images/' + logo)"
      alt=""
      class="w-24 mx-auto mb-2"
    />
  </div>
</template>
<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import theme from '/theme.json';
export default {
  setup() {
    const route = useRoute();
    const logo = ref(null);
    const themeName = computed(() => route.query.theme);

    logo.value = theme['default'].logo;

    watch(themeName, (newThemeName) => {
      if (theme[newThemeName]) logo.value = theme[newThemeName].logo;
    });

    return { logo };
  },
};
</script>
