<template>
  <div class="flex justify-center text-xs text-primary space-x-4 py-2">
    <p v-html="footer"></p>
  </div>
</template>
<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import theme from '/theme.json';
export default {
  setup() {
    const route = useRoute();
    const footer = ref(null);
    const themeName = computed(() => route.query.theme);

    footer.value = theme['default'].footer;

    watch(themeName, (newThemeName) => {
      if (theme[newThemeName]) footer.value = theme[newThemeName].footer;
    });

    return { footer };
  },
};
</script>
